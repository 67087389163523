<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar title="我的收藏" left-arrow @click-left="$router.back()" />
    </van-sticky>
    <!-- <van-search
      v-model="page.filterData.keyWords"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    /> -->

    <van-list
      v-model="upLoading"
      :finished="finished"
      @load="onLoad"
      finished-text="没有更多了"
    >
      <van-cell-group>
        <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
        <van-cell
          @click="toDetail(item)"
          v-for="item in articles"
          :key="item.itemId.toString()"
        >
          <van-col span="24" align="bottom">
            <div
              style="display: table-cell; vertical-align: middle"
              class="zntitle van-multi-ellipsis--l3"
            >
              <h3 style="display: inline-block">{{ item.title }}</h3>
            </div>
            <div>
              <div style="float: left">
                  <van-tag color="#9d1d22" > {{ getTypeText(item.typeId) }}</van-tag>
                </div>
              <div style="float: right">{{ item.creationTime }}</div>
            </div>
          </van-col>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import { getLikeCollectList } from "@/api/commentLike";

//const key = "zn-history";
export default {
  name: "UserCollect",
  data() {
    return {
   
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      page: {
        current: 1,
        pageSize: 10,
      },
    };
  },
  components: {},
  created(){
    this.initData()
  },
  mounted() {
  },
 
  methods: {
    initData(){
        // let { q } = this.$route.query
        // this.page.filterData.keyWords=q;
      //let type = this.$route.query.type;
      
    },
    onSearch() {
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    toDetail(item) {
        
        switch(item.typeId){
            case 100:
            case 101:{
                this.$router.push({ path: "/article/detail", query: { para: item.itemId, type: item.typeId,back:true } });
                return
            }
           
            case 200:{
                this.$router.push({
        path: "/product/detail",
        query: { para: item.itemId,back:true },
      });
                return
            }
             case 300:{
                  this.$router.push({
        path: "/course/detail",
        query: { para: item.itemId,back:true  },
      });
                return
            }
            case 400:{
                this.$router.push({ path: "/bbs/detail", query: { para: item.itemId,back:true} });
                return
            }
            case 500:
                {
                 this.$router.push({ path: "/clouddisk", query: { folderID: item.itemId,back:true } });
                return
            }
            case 501:{
                this.$router.push({
          path: "/clouddisk/detail",
          query: { para: item.itemId,back:true },
        });
                return
            }

            default:{
                return ;
            }
        }
      
    },

    getTypeText(id){
        switch(id){
            case 100:{
                return"中酿资讯";
            }
            case 101:{
                return"中酿公告";
            }
            case 200:{
                return"中酿产品";
            }
             case 300:{
                return"中酿心声";
            }
            case 400:{
                return"中酿心声";
            }
            case 500:
            case 501:{
                return"资料库";
            }

            default:{
                return "";
            }
               
          
        }
    },

    async onLoad() {
      let aresult = await getLikeCollectList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
