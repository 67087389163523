import request from '@/utils/request'


export function getCommentList(params) {
  return request({
    url: 'api/CommentLike/List',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
//我收到的评论
export function getMyAcceptCommentList(params) {
  return request({
    url: 'api/CommentLike/ListByUser',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function getMyAcceptLikeList(params) {
  return request({
    url: 'api/CommentLike/LikeListByAcceptUser',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function getItmeLikeList(params) {
  return request({
    url: 'api/CommentLike/ItemLike',///api/CommentLike/ItemLike
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}


// /api/CommentLike/LikeCollectList
export function getLikeCollectList(params) {
  return request({
    url: 'api/CommentLike/LikeCollectList',///api/CommentLike/ItemLike
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function AddCommentInfo(params) {
  return request({
    url: '/api/CommentLike/Add',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
export function AddLike(params) {
  return request({
    url: '/api/CommentLike/AddLike',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}


export function UpdateCommentLikeCountInfo(params) {
  return request({
    url: '/api/CommentLike/UpdateLikeCount',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
//获取评分
export function GetCommentScore(params) {
  return request({
    url: '/api/CommentLike/GetCommentScore',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
//新增评分
export function AddCommentScore(params) {
  return request({
    url: '/api/CommentLike/AddCommentScore',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
//获取评分项目
export function GetCommentScoreType(itemType) {
  return request({
    url: `/api/CommentLike/GetCommentScoreType?typeid=${itemType}`
  })
}

//点赞用户数据
export function getCommentLikeUserList(params) {
  return request({
    url: 'api/CommentLike/GetLikeUserList',
    params: { ...params },
    method: 'get',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}




